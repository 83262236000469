import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_TAGLINE, APP_NAME, APP_URL, DEMO_TOURNAMENT_ID } from '../../Configs/appConfig';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link, withRouter } from 'react-router-dom';
import * as routes from '../../Configs/routes';
import DevInfo from './DevInfo';
import JumbotronButton from '../MicroComponents/JumbotronButton';
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import regFormPic from '../../Assets/Images/reg-form.webp';
import enterResultsPic from '../../Assets/Images/enter-results.webp';
import sampleStatsPic from '../../Assets/Images/statsd.webp';
import createTourneyPic from '../../Assets/Images/create-tourney.webp';
import FeatureItem from './FeatureItem';
import { dialogQueue } from '../../functions/common';

class HomePage extends React.Component {
    render(){
        return (
        <>
            <Helmet>
                <title>{APP_NAME} - {APP_TAGLINE}</title>
            </Helmet>
            <div className="lander"> 
                <Container fluid className="jumbotron">
                    <Row className="text-center">
                        <Col xs={12}>
                            <img src={APP_URL+'/logo512.png'} alt={APP_NAME} className="img-fluid jumbotron-img"/>
                        </Col>
                        <Col xs={12}>
                            <p className="jumbotron-heading">Managing eSports tournaments made easy with {APP_NAME}</p>
                        </Col>
                        <Col xs={12} className="jumbotron-text pl-3 pr-3">
                            <Typography use="body1">{APP_NAME} is a tournament management app made for eSports lover. Manage multiple teams and matches. Points table is generated automatically. {APP_NAME} Comes with inbuilt Points table maker and registration forms.</Typography>
                        </Col>
                        <Col xs={12}>
                            <Link to={routes.ADMIN}><JumbotronButton/></Link>
                            {/*<a href='https://play.google.com/store/apps/details?id=in.tournify.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img alt='Get it on Google Play' style={{width: "260px", height: "auto"}} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                            </a>*/}
                        </Col>
                        <Col xs={12} className="p-1">
                            <Link to={routes.TOURNAMENTS}><JumbotronButton text="Find Tournaments"/></Link>
                        </Col>
                        <Col xs={12} className="p-1">
                            <Link to={routes.ADMIN+'/'+DEMO_TOURNAMENT_ID} className="link-unstyled">
                                <Button raised theme={["onSecondary","secondaryBg"]} onClick={(e)=>{
                                    e.preventDefault();
                                    dialogQueue.confirm({
                                        title: "Opening Sample Tournament",
                                        body: "Any attempt to modify tournament settings and data will fail.",
                                        acceptLabel: <Button raised>OK</Button>,
                                        cancelLabel: "Cancel"
                                    })
                                    .then(res=>{
                                        if(res){
                                            this.props.history.push(routes.ADMIN+'/'+DEMO_TOURNAMENT_ID);
                                        }
                                    })
                                }}>
                                    Explore Demo Tourney
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="features">
                    <Row className="ml-lg-5 mr-lg-5">
                        {getFeatures().map((feature,i)=>(
                            <Col md={6} lg={3} className="p-2" key={'feature'+i}>
                                <FeatureItem 
                                    {...feature}
                                    />
                            </Col>
                        ))}
                    </Row>
                </Container>
                <Container className="mb-5">
                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center">
                            <img className="img-fluid rounded border" width="300px" src={createTourneyPic} alt="Create Tournament Form"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                            <div>
                                <h3 className="lead jumbotron-heading">
                                Create and manage tournaments in a go.  
                                </h3>
                                <ul className="list-style-tick m-1">
                                    <li>
                                        Easy creation using a single form.
                                    </li>
                                    <li>
                                        Manage multiple matches and teams.
                                    </li>
                                    <li>
                                        Add your own point system.
                                    </li>
                                    <li>
                                        Add or disqualify team with a single click.
                                    </li>
                                    <li>
                                        Manage reports for each and every match. 
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center order-sm-last">
                            <img className="img-fluid rounded border" width="300px" src={enterResultsPic} alt="Enter Match results"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                            <div>
                                <h3 className="lead jumbotron-heading order-sm-first">
                                    Adding results of match made easy
                                </h3>
                                <p>
                                    The process of entering results data of a match has been been super user friendly. User experience has been the top most priority. 
                                </p>
                                <ul className="list-style-tick m-1">
                                    <li>
                                        Add the participating teams once.
                                    </li>
                                    <li>
                                        While entering results data, just select the team and no. of kills. We take care of the points calculation.
                                    </li>
                                    <li>
                                        Select a team by just typing its player's name or the team name itself.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center">
                            <img className="img-fluid rounded" width="300px" src={sampleStatsPic} alt="Demo Points Table"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                           <div>
                           <h3 className="lead jumbotron-heading">
                                Auto-generated beautiful points table
                            </h3>
                            <p>
                                A beautiful points table is generated for your tournament. The standings are calculated using the points system provided during the creation of points table.
                            </p>
                            <ul className="list-style-tick m-1">
                                <li>
                                    A dedicated points table web page is created solely for your tournament.
                                </li>
                                <li>
                                    Points table is updated automatically each time you enter results of a new match.
                                </li>
                                <li>
                                    Option to export the points table to an image file.
                                </li>
                                <li>
                                    Detailed analytics.
                                </li>
                            </ul>
                            <ul className="list-unstyled ml-2">
                                <li>
                                    <Link to={routes.STATS+'/'+DEMO_TOURNAMENT_ID} style={{textDecoration: "none"}}>
                                        <Button raised label="SEE DEMO"/>
                                    </Link>
                                </li>
                            </ul>
                           </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center order-sm-last">
                            <img className="img-fluid rounded border" width="300px" src={regFormPic} alt="Demo Registration Form"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                           <div>
                           <h3 className="lead jumbotron-heading">
                                Comes with pre-built registration form
                            </h3>
                            <p className="m-1">
                                With each eSports tournament you will get a dedicated registration form.
                            </p>
                            <ul className="list-style-tick m-1">
                                <li>
                                    Accept team's email, discord id, phone number etc.
                                </li>
                                <li>
                                    Accept team and player logo.
                                </li>
                                <li>
                                    Set maximum and minimum player required for registration.
                                </li>
                                <li>
                                    Approve registration or decline. Its up to you.
                                </li>
                            </ul>
                           </div>
                        </Col>
                    </Row>
                </Container>
                <DevInfo/>
            </div>
        </>
        );
    }
}

export default withRouter(HomePage);

function getFeatures(){
    return [{
        icon: 'insert_emoticon',
        title: 'Easy Tournament Management',
        body: 'Easily manage eSports tournament from a single platform'
    },{
        icon: 'poll',
        title: 'Beautiful Points Table',
        body: 'Points table are automatically managed as you enter results for each match.'
    },{
        icon: 'analytics',
        title: 'Detailed Stats',
        body: 'Get stats page thats shows teams and player statistics. Top performers, Killboard Leaders, and more'
    },{
        icon: 'military_tech',
        title: 'Top Fraggers',
        body: 'Get top fraggers list in the stats page.'
    },{
        icon: 'image',
        title: 'Support Logos',
        body: 'Set player logos and team Logos or let them upload their logos using registration forms.'
    },{
        icon: 'description',
        title: 'Registration Form',
        body: 'Use registration form for accepting registrations for your tournament.'
    },{
        icon: 'calculate',
        title: 'Custom Points System',
        body: 'Set your own points system or use default one.'
    },{
        icon: 'sports_esports',
        title: 'All Royal Battle Games',
        body: 'Use esportsweb for all types of royal battle games or multiplayer TDM games.'
    }]
}