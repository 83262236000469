import React from 'react';
import Container from 'react-bootstrap/Container';

export default function PageLoader(){

    return (
        <Container className="loader-container">
            <div className="loader-wrapper"> 
                <div className="loader-p"/>
            </div>
        </Container>
    );
}