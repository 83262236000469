import { TOURNAMENT } from "../../Models/Tournament"

export const newTournament = {
    name: '',
    type:'',
    killMode: ''
}

export const basicTournamentSettings = {
    name: '',
    type: '',
    price: '',
    isPublished: false,
    status: TOURNAMENT.UPCOMING,
    organiser: ''
}

export const newTeam = {
    name: '',
    playerList: [{
        name: '',
        logo: '',
        igid: ''
    }],
    email: '',
    phone: '',
    countryCode: '91',
    discord: '',
    logo: ''
}

export const pointsTableSettings = {
    tiePref: TOURNAMENT.TIE_PREF.KILL,
    pointSystem: {
        killPoint: '1',
        placePoint: '1=20,\n2=14,\n3=10,\n4=8,\n5=7,\n6=6,\n7=5,\n8=4,\n9=3,\n10=2,\n11-16=1'
    }
}

export const newMatch = {
    map: 'NONE',
    points: [],
    current: {
        teamName: '',
        kills: '',
        teamId: null,
        playerKills: []
    }
}

export const newFormSettings = {
    desc : {},
    afterDesc : {},
    askEmail : false,
    askNumber : false,
    askDiscord : false,
    askTeamLogo : false,
    askPlayerLogo : false,
    maxPlayer : 1,
    minPlayer : 1,
}