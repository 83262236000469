import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Login from "./Login";
const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  emailVerified,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isVerifying ? (
          <div />
        ) : ( isAuthenticated ? 
          <Component {...props} />
          : 
          <Login/>
        )
      }
    />
  );
}

const mapStateToProps = state => ({
  emailVerified: state.user.user && state.user.user.emailVerified ? true : false,
  isAuthenticated: !!state.user.user,
  isVerifying: false
});

export default connect(mapStateToProps)(ProtectedRoute);
