import React from 'react';

export default function PaypalBadge(){
    return (
        <table style={{border:"0", cellpadding:"10", cellspacing:"0", align:"center", margin: "2px"}}>
            <tbody>
            <tr>
                <td align="center"></td>
            </tr>
            <tr>
                <td align="center">
                    <a href="https://www.paypal.com/in/webapps/mpp/paypal-popup" title="How PayPal Works" onClick={(e)=>{
                        e.preventDefault();
                        window.open('https://www.paypal.com/in/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); 
                        return false;
                    }}>
                        <img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/IN/logo-center/logo-center-other-options-blue-now-accepting-pp.png" border="0" alt="Now accepting PayPal"
                            width="130px"
                        />
                    </a>
                    <div style={{textAlign:"center"}}>
                        <a href="https://www.paypal.com/in/webapps/mpp/how-paypal-works" target="_blank"  without="true" rel="noopener noreferrer">
                            <font size="1.5" face="Arial" color="#fff">
                                <b>How PayPal Works</b>
                            </font>
                        </a>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    )
}