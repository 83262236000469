import { CREATED_TOURNAMENT, CREATING_TOURNAMENT, CREATE_TOURNAMENT_ERROR, LOADING_TOURNAMENTS, LOADING_TOURNAMENTS_ERROR, LOADED_TOURNAMENTS, LOADING_ACTIVE_TOURNAMENT, LOADING_ACTIVE_TOURNAMENT_ERROR, LOADED_ACTIVE_TOURNAMENT, SET_TOURNAMENT_LOGO, SETTING_TOURNAMENT_LOGO_ERROR, SETTING_TOURNAMENT_LOGO, UPDATING_TOURNAMNT, UPDATING_TOURNAMNT_ERROR, UPDATED_TOURNAMENT, CREATING_TEAM, CREATING_TEAM_ERROR, CREATED_TEAM, RESET_CREATED_TEAM, SAVED_POINTS_SYSTEM, SAVING_POINTS_SETTING, SAVING_MATCH, SAVING_MATCH_ERROR, SAVED_MATCH, SAVED_MATCH_RESET, LOADING_FORM_SETTINGS, LOADING_FORM_SETTINGS_ERROR, LOADED_FORM_SETTINGS, LOADED_FORM_SETTINGS_BY_ID, SAVING_FORM_SETTINGS, SAVED_FORM_SETTINGS, TOGGLING_FORM_STATUS, TOGGLED_FORM_STATUS, APPROVED_RESPONSE, RESPONSE_REMOVED, DELETED_TEAM, SETTING_TOURNAMENT_BANNER, SETTING_TOURNAMENT_BANNER_ERROR, SET_TOURNAMENT_BANNER, DELETED_MATCH, TOURNAMENTS_END_REACHED, DELETED_TOURANAMENT, SAVING_TEAM, SAVED_TEAM, UPDATING_MATCH, UPDATING_MATCH_ERROR, UPDATED_MATCH, SAVED_TOURNAMENT_CONTACT_DATA, SAVING_TOURNAMENT_CONTACT_DATA } from "./tournamentsActionTypes";
import { USER_SIGNED_OUT } from "../User/userActionTypes";
import Tournament from "../../Models/Tournament";
import { dialogQueue } from "../../functions/common";
const initialState = {
    tournaments: [],
    activeTournament: null,
    isCreating: false,
    createError: null,
    isLoading: false,
    loadError: null,
    tournamentsEndReached: false,
    firstLoadComplete: false,
    loadingActiveTournament: false,
    loadingActiveTournamentError: null,
    uploadingLogo: false,
    uploadingLogoError: null,
    uploadingBanner: false,
    uploadingBannerError: null,
    updatingTournament: false,
    updatingTournamentError: null,
    savingTournamentContactData: false,
    creatingTeam: false,
    creatingTeamError: null,
    createdTeam: false,
    savingTeam: false,
    savingPS: false,
    savingMatch: false,
    savingMatchError: null,
    savedMatch: false,
    updatingMatch: false,
    updatingMatchError: null, 
    loadingFormSettings: true,
    loadingFormSettingsError: null,
    formSettings: null,
    savingFormSettings: false,
    togglingFormStatus: false
}

function syncWithTourneyList(tourney,list=[]){
    const i = list.findIndex(T=>T.id === tourney.id);
    if(i!==-1){
        list[i]=tourney;
    }
}
export default function(state=initialState,action){
    let i;
    const newState = JSON.parse(JSON.stringify(state));
    if(newState.activeTournament){
        newState.activeTournament = new Tournament(newState.activeTournament);
    }
    switch(action.type){
        case USER_SIGNED_OUT:
            return initialState;
        case CREATED_TOURNAMENT:
            newState.isCreating = false;
            newState.createError = null;
            newState.tournaments.unshift(action.payload);
            return newState;
        case CREATING_TOURNAMENT:
            newState.isCreating = true;
            newState.createError = null;
            return newState;
        case CREATE_TOURNAMENT_ERROR:
            newState.isCreating = false;
            newState.createError = action.payload;
            return newState;
        case LOADING_TOURNAMENTS:
            newState.isLoading = true;
            newState.loadError = null;
            return newState;
        case LOADING_TOURNAMENTS_ERROR:
            newState.isLoading = false;
            newState.loadError = action.payload;
            return newState;
        case LOADED_TOURNAMENTS:
            newState.isLoading = false;
            newState.loadError =  null;
            newState.firstLoadComplete = true;
            action.payload.forEach(tourney=>newState.tournaments.push(tourney));
            return newState;
        case TOURNAMENTS_END_REACHED:
            newState.isLoading = false;
            newState.loadError =  null;
            newState.tournamentsEndReached = true;
            return newState;
        case DELETED_TOURANAMENT:
            i = newState.tournaments.findIndex(T => T.id === action.payload);
            if(i!==-1){
                newState.tournaments.splice(i,1);
            }
            return newState;
        case LOADING_ACTIVE_TOURNAMENT:
            newState.loadingActiveTournament = true;
            newState.loadingActiveTournamentError = null;
            return newState;
        case LOADING_ACTIVE_TOURNAMENT_ERROR:
            newState.loadingActiveTournament = false;
            newState.loadingActiveTournamentError = action.payload;
            return newState;

        /**
         * action.payload has been already cast to their respective types in their reducers: Team,Tournament
         */
        case LOADED_ACTIVE_TOURNAMENT:
            newState.loadingActiveTournament = false;
            newState.activeTournamentError = null;
            newState.activeTournament =  action.payload;
            syncWithTourneyList(newState.activeTournament,newState.tournaments);
            return  newState;
        
        case SETTING_TOURNAMENT_LOGO:
            newState.uploadingLogo = true;
            newState.uploadingLogoError = null;
            return newState;
        
        case SETTING_TOURNAMENT_LOGO_ERROR:
            newState.uploadingLogo = true;
            newState.uploadingLogoError = action.payload;
            return newState;

        case SET_TOURNAMENT_LOGO:
            if(newState.activeTournament.id === action.payload.id)
                newState.activeTournament.logo = action.payload.downloadUrl;
            syncWithTourneyList(newState.activeTournament,newState.tournaments);
            newState.uploadingLogo = false;
            newState.uploadingLogoError = null;
            return newState;
            
        case SETTING_TOURNAMENT_BANNER:
            newState.uploadingBanner = true;
            newState.uploadingBannerError = null;
            return newState;
        
        case SETTING_TOURNAMENT_BANNER_ERROR:
            newState.uploadingBanner = true;
            newState.uploadingBannerError = action.payload;
            return newState;

        case SET_TOURNAMENT_BANNER:
            if(newState.activeTournament.id === action.payload.id)
                newState.activeTournament.banner = action.payload.downloadUrl;
            syncWithTourneyList(newState.activeTournament,newState.tournaments);
            newState.uploadingBanner = false;
            newState.uploadingBannerError = null;
            return newState;

        case UPDATING_TOURNAMNT:
            newState.updatingTournament = true;
            newState.updatingTournamentError = null;
            return newState;

        case UPDATING_TOURNAMNT_ERROR:
            newState.updatingTournament = false;
            newState.updatingTournamentError = action.payload;
            return newState;

        case UPDATED_TOURNAMENT:
            i = newState.tournaments.findIndex(T => T.id === action.payload.id);
            if(i!==-1)
                newState.tournaments[i] = new Tournament({...newState.tournaments[i], ...action.payload.newData});
            if(newState.activeTournament && newState.activeTournament.id === action.payload.id)
                newState.activeTournament = new Tournament({...newState.activeTournament, ...action.payload.newData});
            newState.updatingTournament = false;
            newState.updatingTournamentError = null;
            return newState;

        case SAVING_TOURNAMENT_CONTACT_DATA:
            newState.savingTournamentContactData = true;
            return newState;

        case SAVED_TOURNAMENT_CONTACT_DATA:
            newState.savingTournamentContactData = false;
            if(newState.activeTournament && newState.activeTournament.id === action.payload.id)
                newState.activeTournament.contactItems = action.payload.contactData;
            syncWithTourneyList(newState.activeTournament,newState.tournaments);
            return newState;
            
        case CREATING_TEAM:
            newState.creatingTeam = true;
            newState.creatingTeamError = null;
            newState.createdTeam = false;
            return newState;
        
        case CREATING_TEAM_ERROR:
            newState.creatingTeam = false;
            newState.creatingTeamError = action.payload;
            newState.createdTeam = false;
            return newState;
        
        case CREATED_TEAM:
            if(newState.activeTournament && newState.activeTournament.id === action.payload.id){
                newState.activeTournament.teams.unshift(action.payload.team);
                syncWithTourneyList(newState.activeTournament,newState.tournaments);      
            }
            newState.creatingTeam = false;
            newState.creatingTeamError = null;
            newState.createdTeam = true;
            return newState;      
        case RESET_CREATED_TEAM:
            newState.createdTeam = false;
            return newState;
        
        case DELETED_TEAM: 
            if(newState.activeTournament){
                i = newState.activeTournament.teams.findIndex(T => T.id === action.payload);
                if(i!==-1){
                    newState.activeTournament.teams.splice(i,1);
                    syncWithTourneyList(newState.activeTournament,newState.tournaments);
                }
            }
            return newState;

        case SAVING_TEAM:
            newState.savingTeam = true;
            return newState;

        case SAVED_TEAM:
            if(newState.activeTournament && newState.activeTournament.id === action.payload.id){
                i = newState.activeTournament.teams.findIndex(T => action.payload.team.id === T.id);
                if(i!==-1){
                    newState.activeTournament.teams[i] = action.payload.team;
                    syncWithTourneyList(newState.activeTournament,newState.tournaments);
                }      
            }
            newState.savingTeam = false;
            return newState;
        case SAVED_POINTS_SYSTEM:
            newState.savingPS = false;
            dialogQueue.alert({
                title: "Points System Saved!",
                acceptLabel: "OK"
            });
            return newState;
        
        case SAVING_POINTS_SETTING:
            newState.savingPS = true;
            return newState;
        
        case SAVING_MATCH:
            newState.savingMatch = true;
            newState.savingMatchError = null;
            return newState;

        case SAVING_MATCH_ERROR:
            newState.savingMatch = false;
            newState.savingMatchError = action.payload;
            return newState;
            
        case SAVED_MATCH:
            newState.savingMatch = false;
            newState.savingMatchError = null;
            newState.savedMatch = true;
            if(newState.activeTournament && newState.activeTournament.id === action.payload.id){
                newState.activeTournament.matches.unshift(action.payload.match)
                syncWithTourneyList(newState.activeTournament,newState.tournaments);      
            }
            return newState;
            
        case SAVED_MATCH_RESET:
            newState.savedMatch = false;
            return newState;
        
        case UPDATING_MATCH: 
            newState.updatingMatch = true;
            newState.updatingMatchError = null;
            return newState;
        
        case UPDATING_MATCH_ERROR:
            newState.updatingMatch = false;
            newState.updatingMatchError = action.payload;
            return newState;
        
        case UPDATED_MATCH:
            newState.updatingMatch = false;
            newState.updatingMatchError = null;
            if(newState.activeTournament && newState.activeTournament.id === action.payload.tid){
                const i = newState.activeTournament.matches.findIndex(match => action.payload.match.id === match.id);
                newState.activeTournament.matches[i] = action.payload.match;
                syncWithTourneyList(newState.activeTournament,newState.tournaments);      
            }
            return newState;
        case DELETED_MATCH: 
            if(newState.activeTournament){
                i = newState.activeTournament.matches.findIndex(T => T.id === action.payload);
                if(i!==-1){
                    newState.activeTournament.matches.splice(i,1);
                    syncWithTourneyList(newState.activeTournament,newState.tournaments);
                }
            }
            return newState;

        case LOADING_FORM_SETTINGS:
            newState.loadingFormSettings = true;
            newState.loadingFormSettingsError = null;
            return newState;
            
        case LOADING_FORM_SETTINGS_ERROR:
            newState.loadingFormSettings = false;
            newState.loadingFormSettingsError = action.payload;
            return newState;
        
        case LOADED_FORM_SETTINGS:
            newState.loadingFormSettings = false;
            newState.loadingFormSettingsError = null;
            if(newState.activeTournament && newState.activeTournament.id === action.payload.id){
                newState.activeTournament.formRef = action.payload.form;
                syncWithTourneyList(newState.activeTournament,newState.tournaments);      
            }   
            return newState;

        case LOADED_FORM_SETTINGS_BY_ID:
            newState.loadingFormSettings = true;
            newState.loadingFormSettingsError = null;
            newState.formSettings = action.payload  
            return newState;

        case SAVING_FORM_SETTINGS:
            newState.savingFormSettings = true;
            return newState;
        
        case SAVED_FORM_SETTINGS:
            newState.savingFormSettings = false;
            return newState;
        
        case TOGGLING_FORM_STATUS:
            newState.togglingFormStatus = true;
            return newState;
            
        case TOGGLED_FORM_STATUS:
            newState.togglingFormStatus = false;
            return newState;
        
        case APPROVED_RESPONSE:
            i = newState.activeTournament.formRef.pending.findIndex(R => R.id === action.payload.id)
            if(i!==-1){
                newState.activeTournament.formRef.pending.splice(i,1);
                syncWithTourneyList(newState.activeTournament,newState.tournaments);  
            }
            newState.activeTournament.formRef.approved.push(action.payload);
            return newState;

        case RESPONSE_REMOVED:
            i = newState.activeTournament.formRef.pending.findIndex(R => R.id === action.payload)
            if(i!==-1){
                newState.activeTournament.formRef.pending.splice(i,1);
            }
            i = newState.activeTournament.formRef.approved.findIndex(R => R.id === action.payload)
            if(i!==-1){
                newState.activeTournament.formRef.approved.splice(i,1);
            }
            syncWithTourneyList(newState.activeTournament,newState.tournaments);  
            return newState;
        default:
            return state;
    }
}