import { USER_SIGNED_OUT } from '../User/userActionTypes';
import { STATS_LOADING, STATS_FAILED, STATS_LOADED } from './statsActionTypes';

const initialState = {
    standings: null,
    isLoading: true,
    errMess: null,
    tourneyData: null
}

export default function StatsReducer(state = initialState,action){
    switch(action.type){
        
        case USER_SIGNED_OUT:
            return JSON.parse(JSON.stringify(initialState));
            
        case STATS_LOADING:
            return {...state, isLoading: true, errMess: null};

        case STATS_FAILED:
            return {...state, isLoading: false, errMess: action.payload};
        
        case STATS_LOADED:
            return {...state, isLoading: false, errMess: null, standings: action.payload.stats, tourneyData: action.payload.tourney};
        default: 
            return state;
    }
}