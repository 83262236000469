import React, { lazy, Suspense } from 'react';
import Header from "../Components/Header"
import Footer from '../Components/Footer';
import * as routes from '../Configs/routes';
import HomePage from './Home/HomePage';
import PageLoader from './MicroComponents/PageLoader';
import { APP_NAME, APP_TAGLINE } from '../Configs/appConfig';
import { Helmet } from 'react-helmet';
import BotLogin from './BotLogin';
const About = lazy(() => import('./About/About'));
const TournamentView = lazy(()=> import('./Admin/TournamentView'))
const RegistrationView = lazy(()=> import('./Registration/RegistrationView'));
const Stats = lazy(()=> import('./Stats/index'));
const Privacy = lazy(()=> import('./Others/Privacy'));
const PublicTournaments = lazy(()=> import('./PublicTournaments/PublicTournaments'));
const TournamentPage = lazy(()=> import('./PublicTournaments/TournamentPage'));
const AdminPanel = lazy(()=> import('./Admin/AdminPanel'));
const Dashboard =  lazy(()=> import('./Admin/Dashboard'));
const ContactUs = lazy(()=>import('./Contact/Contact'));
const TermsOfUse = lazy(() => import('./Others/TermsOfUse'));
const Disclaimer = lazy(() => import('./Others/Disclaimer'));
const FAQ = lazy(() => import('./Others/faq'));
const Pricing = lazy(() => import('./Pricing/Pricing'));
const PointsTableMaker = lazy(()=> import('./PointsTableMaker/PointsTableMaker'));
const Payment = lazy(() =>  import('./Pricing/Payment'));
const Account = lazy(() => import('./Account/Account'));
const RefundPolicy = lazy(() => import('./Others/RefundPolicy'));

export const pages = [{
    displayName: 'Home',
    path: routes.HOME,
    exact: true,
    component: () => (
        <>
            <Header/>
            <Helmet>
                <title>{APP_NAME} | {APP_TAGLINE}</title>
            </Helmet>
            <HomePage/>
            <Footer/>
        </>
    )
},{
    displayName: 'Tournaments',
    path: routes.TOURNAMENTS,
    exact: true,
    component: () => (
        <>
            <Header/>
                <Helmet>
                    <title>Tournaments | {APP_NAME}</title>
                </Helmet>
                <Suspense fallback={<PageLoader/>}>
                    <PublicTournaments/>
                </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Privacy Policy',
    path: routes.PRIVACY,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Privacy | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}>
                <Privacy/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Dashboard',
    path: routes.ADMIN,
    exact: true,
    auth: true,
    component: () => (
        <>
            <Header/>
            <Helmet>
                <title>Dashboard | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}>
                <Dashboard/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Tournament',
    path: routes.TOURNAMENT_VIEW+'/:id',
    exact: false,
    auth: true,
    component: ({match}) => (
        <>
            <Header/>
                <Suspense fallback={<PageLoader/>}>
                    <TournamentView id={match.params.id}/>
                </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Registration Form',
    path: routes.REGISTER+'/:id',
    exact: false,
    component: ({match}) => (
        <>
            <Header/>
            <Suspense fallback={<PageLoader/>}>
                <RegistrationView id={match.params.id}/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Stats Page',
    path: routes.STATS+'/:id',
    exact: false,
    component: ({match}) => (
        <>
            <Header/>
            <Suspense fallback={<PageLoader/>}>
                <Stats id={match.params.id}/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Tournament Page',
    path: routes.TOURNAMENTS+'/:id',
    exact: false,
    component: ({match}) => (
        <>
            <Header/>
                <Suspense fallback={<PageLoader/>}>
                    <TournamentPage id={match.params.id}/>
                </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Admin Panel',
    path: routes.ADMIN_PANEL,
    exact: false,
    component: ({match}) => (
        <>
            <Header/>
                <Helmet>
                    <title>Manage Admins | {APP_NAME}</title>
                </Helmet>
                <Suspense fallback={<PageLoader/>}>
                    <AdminPanel/>
                </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Account Information',
    path: routes.ACCOUNT,
    exact: true,
    auth: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>My Account | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <Account/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Contact Us',
    path: routes.CONTACT,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Contact Us | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <ContactUs/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Terms of Use',
    path: routes.TERMS_OF_USE,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Terms of Use | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <TermsOfUse/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Disclaimer',
    path: routes.DISCLAIMER,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Disclaimer | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <Disclaimer/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Refund Policy',
    path: routes.REFUND_POLICY,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Refund Policy | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <RefundPolicy/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Frequently Asked Questions (FAQ)',
    path: routes.FAQ,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Frequently Asked Questions (FAQ) | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <FAQ/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Plans & Pricing',
    path: routes.PRICING,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Plans & Pricing | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <Pricing/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Points Table Maker',
    path: routes.POINTS_TABLE_MAKER+'/:id',
    exact: false,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>Points Table Maker | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <PointsTableMaker id={match.params.id}/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'About Us',
    path: routes.ABOUT,
    exact: true,
    component: ({match}) => (
        <>
            <Header/>
            <Helmet>
                <title>About Us | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <About/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Payment',
    path: routes.PAYMENT,
    exact: true,
    component: (props) => (
        <>
            <Header/>
            <Helmet>
                <title>Payment | {APP_NAME}</title>
            </Helmet>
            <Suspense fallback={<PageLoader/>}> 
                <Payment {...props}/>
            </Suspense>
            <Footer/>
        </>
    )
},{
    displayName: 'Payment',
    path: routes.BOT_LOGIN,
    exact: true,
    component: (props) => (
        <>
            <Header/>
            <Helmet>
                <title>Crawler Login | {APP_NAME}</title>
            </Helmet>
            <BotLogin/>
            <Footer/>
        </>
    )
}]