import firebase from "firebase/app";
import { useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, DB } from "../../Configs/appConfig";
import { updateUserCredit } from "../../redux/User/userActionCreator";

const requestUserFreeCredits = async () => {
	try {
		const token = await firebase.auth().currentUser.getIdToken();
		const response = await fetch(API_BASE_URL + '/functions/requestFreeCredits', {
			method: 'POST',
			headers: {
				"Authorization": "Bearer " + token,
				"Content-Type": "application/json"
			},
		})
		const creditsInfo = await response.json();
		return creditsInfo;
	}
	catch (e) {
		console.error(e);
	}
	return {
		credits: 0
	}
}
function UserCreditObserver(){
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();
	const lastUser = useRef("");
	useEffect(() => {
		let unsubscribe;
		if (!user?.uid) {
			dispatch(updateUserCredit({
				credits: 0
			}));
		} else {
			if(user.uid !== lastUser.current && unsubscribe){
				unsubscribe();
			}
			lastUser.current = user.uid;
			if(user.subscription){
				dispatch(updateUserCredit({
					credits: "infinity"
				}));
			} else {
				unsubscribe = firebase
					.firestore()
					.collection(DB.USERS)
					.doc(user.uid)
					.onSnapshot((doc) => {
						if(doc.exists){
							dispatch(updateUserCredit(
								doc.data().creditsInfo || {
									credits: 0
								}
							));
						} else {
							requestUserFreeCredits()
								.then(creditsInfo => {
									dispatch(updateUserCredit(creditsInfo))
								});
						}
					})
			}
		}
		return () => unsubscribe && unsubscribe()
	},[dispatch, user?.subscription, user?.uid]);
	return null;
}

export default connect()(UserCreditObserver)