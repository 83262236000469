export const TOURNAMENTS = '/tournaments';
export const ADMIN = '/dashboard';
export const ACCOUNT = "/account"
export const HOME = '/';
export const CONTACT = '/contact';
export const TERMS_OF_USE = '/termsofuse'
export const DISCLAIMER = "/disclaimer"
export const PRIVACY ="/privacy";
export const PRICING ="/pricing";
export const PAYMENT = '/payment';
export const REFUND_POLICY = "/refund-policy";
export const FAQ = "/faq";
export const ABOUT = "/about";
export const POINTS_TABLE_MAKER = "/points-table-maker";
export const TOURNAMENT_VIEW = ADMIN;  
export const REGISTER = '/register';
export const STATS = '/stats';
export const BASIC_SETTING = '/basic-settings';
export const FORM_SETTINGS = '/forms';
export const CONTACT_SETTINGS = '/contact-settings'
export const ENTER_RESULTS = '/enter-results';
export const MANAGE_TEAMS = '/teams';
export const MANAGE_POST = '/post';
export const POINTS_TABLE_SETTING = '/points-table';
export const VIEW_MATCHES = '/view-matches';
export const EDIT_MATCH = '/edit_match';
export const CUSTOM_THEME = "/custom-theme";
export const PREBUILT_THEME = "/prebuilt-theme";
export const BOT_LOGIN = "/bot-login";
export const ADMIN_PANEL = "/admin-panel"