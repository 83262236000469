import {TOURNAMENT} from '../../Models/Tournament';
import { LOADING_PUBLIC_TOURNAMENTS, LOADING_PUBLIC_TOURNAMENTS_ERROR, LOADED_PUBLIC_TOURNAMENTS, PUBLIC_TOURNAMENTS_END_REACHED, CLEAR_PUBLIC_TOURNAMENTS, LOADING_PUBLIC_CURRENT, LOADING_PUBLIC_CURRENT_ERROR, LOADED_PUBLIC_CURRENT } from './publicActionTypes';
const initialState = {
    loading: false,
    error: {
        [TOURNAMENT.COMPLETED]: null,
        [TOURNAMENT.UPCOMING]: null,
        [TOURNAMENT.ONGOING]: null
    },
    tournaments: {
        [TOURNAMENT.COMPLETED]: [],
        [TOURNAMENT.UPCOMING]: [],
        [TOURNAMENT.ONGOING]: []
    },
    reachedEnd: {
        [TOURNAMENT.COMPLETED]: false,
        [TOURNAMENT.UPCOMING]: false,
        [TOURNAMENT.ONGOING]: false
    },
    loadingCurrent: true,
    current: null,
    loadingCurrentError: null
}

export default function Public(state=initialState,action){
    const newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case LOADING_PUBLIC_TOURNAMENTS:
            newState.loading = true;
            newState.error = initialState.error;
            return newState;
        case LOADING_PUBLIC_TOURNAMENTS_ERROR:
            newState.loading = false;
            newState.error[action.payload.status] = action.payload.errMess;
            return newState;
        
        case LOADED_PUBLIC_TOURNAMENTS:
            newState.loading = false;
            newState.error = initialState.error;
            action.payload.tournaments.forEach(T => {
                newState.tournaments[action.payload.status].push(T);
            });
            return newState;
        
        case PUBLIC_TOURNAMENTS_END_REACHED:
            newState.reachedEnd[action.payload] = true;
            newState.loading = false;
            return newState;
        
        case CLEAR_PUBLIC_TOURNAMENTS:
            newState.tournaments = initialState.tournaments;
            newState.error = initialState.error;
            newState.reachedEnd = initialState.reachedEnd;
            newState.loading = initialState.loading;
            return newState;

        case LOADING_PUBLIC_CURRENT:
            newState.loadingCurrent = true;
            newState.loadingCurrentEror = null;
            return newState;

        case LOADING_PUBLIC_CURRENT_ERROR:
            newState.loadingCurrent = false;
            newState.loadingCurrentError = action.payload;
            return newState;
        
        case LOADED_PUBLIC_CURRENT:
            newState.loadingCurrent = false;
            newState.loadingCurrentError = null;
            newState.current = action.payload;
            return newState;
        default:
            return state;
    }
}