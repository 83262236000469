import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ADMIN } from '../Configs/routes';
import firebase from './firebase/firebase';

const BotLogin = ({user}) => {
    const onSubmit = (e) => {
        e.preventDefault();
        e.persist();
        const emailEl = document.querySelector("#bot-login #email");
        const passEl = document.querySelector("#bot-login #password")
        firebase.auth().signInWithEmailAndPassword(emailEl.value,passEl.value);
    }
    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const password = urlParams.get('password');
        if(email && password){
            firebase.auth().signInWithEmailAndPassword(email,password);
        }
    })
    if(user){
        return <Redirect to={ADMIN}/>
    } else {
        return (
            <form onSubmit={onSubmit} id="bot-login" className="d-flex flex-column">
                <label for="email" className="m-1">Email</label>
                <input id="email" type="email" name="email" className="m-1"></input>
                <label for="password" className="m-1">Password</label>
                <input id="password" type="password" name="password" className="m-1"></input>
                <button type="submit" className="m-1">
                    Login
                </button>
            </form>
        );
    }
}

const mapState = (state) => {
    return {
        user: state.user.user
    }
}

export default connect(mapState,null)(BotLogin);