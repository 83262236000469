import React from 'react';
import { createDialogQueue } from '@rmwc/dialog';
import {validators as v} from './validator';
import { TEAM } from '../Models/Team';
import firebase from '../Components/firebase/firebase';
import { TOURNAMENT } from '../Models/Tournament';
import { gameMaps } from '../Configs/gameMaps';
export const countryCodes = (
<>  
    <option value="91">India (+91)</option>
    <option value="1">USA (+1)</option>
    <option value="880">Bangladesh (+880)</option>
    <option value="44">UK (+44)</option>
    <option value="213">Algeria (+213)</option>
    <option value="376">Andorra (+376)</option>
    <option value="244">Angola (+244)</option>
    <option value="1264">Anguilla (+1264)</option>
    <option value="1268">Antigua &amp; Barbuda (+1268)</option>
    <option value="54">Argentina (+54)</option>
    <option value="374">Armenia (+374)</option>
    <option value="297">Aruba (+297)</option>
    <option value="61">Australia (+61)</option>
    <option value="43">Austria (+43)</option>
    <option value="994">Azerbaijan (+994)</option>
    <option value="1242">Bahamas (+1242)</option>
    <option value="973">Bahrain (+973)</option>
    <option value="1246">Barbados (+1246)</option>
    <option value="375">Belarus (+375)</option>
    <option value="32">Belgium (+32)</option>
    <option value="501">Belize (+501)</option>
    <option value="229">Benin (+229)</option>
    <option value="1441">Bermuda (+1441)</option>
    <option value="975">Bhutan (+975)</option>
    <option value="591">Bolivia (+591)</option>
    <option value="387">Bosnia Herzegovina (+387)</option>
    <option value="267">Botswana (+267)</option>
    <option value="55">Brazil (+55)</option>
    <option value="673">Brunei (+673)</option>
    <option value="359">Bulgaria (+359)</option>
    <option value="226">Burkina Faso (+226)</option>
    <option value="257">Burundi (+257)</option>
    <option value="855">Cambodia (+855)</option>
    <option value="237">Cameroon (+237)</option>
    <option value="1">Canada (+1)</option>
    <option value="238">Cape Verde Islands (+238)</option>
    <option value="1345">Cayman Islands (+1345)</option>
    <option value="236">Central African Republic (+236)</option>
    <option value="56">Chile (+56)</option>
    <option value="86">China (+86)</option>
    <option value="57">Colombia (+57)</option>
    <option value="269">Comoros (+269)</option>
    <option value="242">Congo (+242)</option>
    <option value="682">Cook Islands (+682)</option>
    <option value="506">Costa Rica (+506)</option>
    <option value="385">Croatia (+385)</option>
    <option value="90">Cyprus - North (+90)</option>
    <option value="357">Cyprus - South (+357)</option>
    <option value="420">Czech Republic (+420)</option>
    <option value="45">Denmark (+45)</option>
    <option value="253">Djibouti (+253)</option>
    <option value="1809">Dominica (+1809)</option>
    <option value="1809">Dominican Republic (+1809)</option>
    <option value="593">Ecuador (+593)</option>
    <option value="20">Egypt (+20)</option>
    <option value="503">El Salvador (+503)</option>
    <option value="240">Equatorial Guinea (+240)</option>
    <option value="291">Eritrea (+291)</option>
    <option value="372">Estonia (+372)</option>
    <option value="251">Ethiopia (+251)</option>
    <option value="500">Falkland Islands (+500)</option>
    <option value="298">Faroe Islands (+298)</option>
    <option value="679">Fiji (+679)</option>
    <option value="358">Finland (+358)</option>
    <option value="33">France (+33)</option>
    <option value="594">French Guiana (+594)</option>
    <option value="689">French Polynesia (+689)</option>
    <option value="241">Gabon (+241)</option>
    <option value="220">Gambia (+220)</option>
    <option value="7880">Georgia (+7880)</option>
    <option value="49">Germany (+49)</option>
    <option value="233">Ghana (+233)</option>
    <option value="350">Gibraltar (+350)</option>
    <option value="30">Greece (+30)</option>
    <option value="299">Greenland (+299)</option>
    <option value="1473">Grenada (+1473)</option>
    <option value="590">Guadeloupe (+590)</option>
    <option value="671">Guam (+671)</option>
    <option value="502">Guatemala (+502)</option>
    <option value="224">Guinea (+224)</option>
    <option value="245">Guinea - Bissau (+245)</option>
    <option value="592">Guyana (+592)</option>
    <option value="509">Haiti (+509)</option>
    <option value="504">Honduras (+504)</option>
    <option value="852">Hong Kong (+852)</option>
    <option value="36">Hungary (+36)</option>
    <option value="354">Iceland (+354)</option>
    <option value="62">Indonesia (+62)</option>
    <option value="964">Iraq (+964)</option>
    <option value="353">Ireland (+353)</option>
    <option value="972">Israel (+972)</option>
    <option value="39">Italy (+39)</option>
    <option value="1876">Jamaica (+1876)</option>
    <option value="81">Japan (+81)</option>
    <option value="962">Jordan (+962)</option>
    <option value="7">Kazakhstan (+7)</option>
    <option value="254">Kenya (+254)</option>
    <option value="686">Kiribati (+686)</option>
    <option value="82">Korea - South (+82)</option>
    <option value="965">Kuwait (+965)</option>
    <option value="996">Kyrgyzstan (+996)</option>
    <option value="856">Laos (+856)</option>
    <option value="371">Latvia (+371)</option>
    <option value="961">Lebanon (+961)</option>
    <option value="266">Lesotho (+266)</option>
    <option value="231">Liberia (+231)</option>
    <option value="218">Libya (+218)</option>
    <option value="417">Liechtenstein (+417)</option>
    <option value="370">Lithuania (+370)</option>
    <option value="352">Luxembourg (+352)</option>
    <option value="853">Macao (+853)</option>
    <option value="389">Macedonia (+389)</option>
    <option value="261">Madagascar (+261)</option>
    <option value="265">Malawi (+265)</option>
    <option value="60">Malaysia (+60)</option>
    <option value="960">Maldives (+960)</option>
    <option value="223">Mali (+223)</option>
    <option value="356">Malta (+356)</option>
    <option value="692">Marshall Islands (+692)</option>
    <option value="596">Martinique (+596)</option>
    <option value="222">Mauritania (+222)</option>
    <option value="222">Mauritius (+230)</option>
    <option value="269">Mayotte (+269)</option>
    <option value="52">Mexico (+52)</option>
    <option value="691">Micronesia (+691)</option>
    <option value="373">Moldova (+373)</option>
    <option value="377">Monaco (+377)</option>
    <option value="976">Mongolia (+976)</option>
    <option value="1664">Montserrat (+1664)</option>
    <option value="212">Morocco (+212)</option>
    <option value="258">Mozambique (+258)</option>
    <option value="95">Myanmar (+95)</option>
    <option value="264">Namibia (+264)</option>
    <option value="674">Nauru (+674)</option>
    <option value="977">Nepal (+977)</option>
    <option value="31">Netherlands (+31)</option>
    <option value="687">New Caledonia (+687)</option>
    <option value="64">New Zealand (+64)</option>
    <option value="505">Nicaragua (+505)</option>
    <option value="227">Niger (+227)</option>
    <option value="234">Nigeria (+234)</option>
    <option value="683">Niue (+683)</option>
    <option value="672">Norfolk Islands (+672)</option>
    <option value="670">Northern Marianas (+670)</option>
    <option value="47">Norway (+47)</option>
    <option value="968">Oman (+968)</option>
    <option value="92">Pakistan (+92)</option>
    <option value="680">Palau (+680)</option>
    <option value="507">Panama (+507)</option>
    <option value="675">Papua New Guinea (+675)</option>
    <option value="595">Paraguay (+595)</option>
    <option value="51">Peru (+51)</option>
    <option value="63">Philippines (+63)</option>
    <option value="48">Poland (+48)</option>
    <option value="351">Portugal (+351)</option>
    <option value="1787">Puerto Rico (+1787)</option>
    <option value="974">Qatar (+974)</option>
    <option value="262">Reunion (+262)</option>
    <option value="40">Romania (+40)</option>
    <option value="7">Russia (+7)</option>
    <option value="250">Rwanda (+250)</option>
    <option value="378">San Marino (+378)</option>
    <option value="239">Sao Tome &amp; Principe (+239)</option>
    <option value="966">Saudi Arabia (+966)</option>
    <option value="221">Senegal (+221)</option>
    <option value="381">Serbia (+381)</option>
    <option value="248">Seychelles (+248)</option>
    <option value="232">Sierra Leone (+232)</option>
    <option value="65">Singapore (+65)</option>
    <option value="421">Slovak Republic (+421)</option>
    <option value="386">Slovenia (+386)</option>
    <option value="677">Solomon Islands (+677)</option>
    <option value="252">Somalia (+252)</option>
    <option value="27">South Africa (+27)</option>
    <option value="34">Spain (+34)</option>
    <option value="94">Sri Lanka (+94)</option>
    <option value="290">St. Helena (+290)</option>
    <option value="1869">St. Kitts (+1869)</option>
    <option value="1758">St. Lucia (+1758)</option>
    <option value="597">Suriname (+597)</option>
    <option value="249">Sudan (+249)</option>
    <option value="268">Swaziland (+268)</option>
    <option value="46">Sweden (+46)</option>
    <option value="41">Switzerland (+41)</option>
    <option value="886">Taiwan (+886)</option>
    <option value="992">Tajikistan (+992)</option>
    <option value="66">Thailand (+66)</option>
    <option value="670">Timor Leste (+670)</option>
    <option value="228">Togo (+228)</option>
    <option value="676">Tonga (+676)</option>
    <option value="1868">Trinidad &amp; Tobago (+1868)</option>
    <option value="216">Tunisia (+216)</option>
    <option value="90">Turkey (+90)</option>
    <option value="993">Turkmenistan (+993)</option>
    <option value="1649">Turks &amp; Caicos Islands (+1649)</option>
    <option value="688">Tuvalu (+688)</option>
    <option value="256">Uganda (+256)</option>
    <option value="380">Ukraine (+380)</option>
    <option value="971">United Arab Emirates (+971)</option>
    <option value="598">Uruguay (+598)</option>
    <option value="998">Uzbekistan (+998)</option>
    <option value="678">Vanuatu (+678)</option>
    <option value="379">Vatican City (+379)</option>
    <option value="58">Venezuela (+58)</option>
    <option value="84">Vietnam (+84)</option>
    <option value="1">Virgin Islands - British (+1)</option>
    <option value="1">Virgin Islands - US (+1)</option>
    <option value="681">Wallis &amp; Futuna (+681)</option>
    <option value="969">Yemen (North)(+969)</option>
    <option value="967">Yemen (South)(+967)</option>
    <option value="260">Zambia (+260)</option>
    <option value="263">Zimbabwe (+263)</option>
  </>
  )

export const validOrEmpty = {
    validateEmail: (email)=>{
        return (v.isEmail(email) && v.maxLength(TEAM.MAX_LENGTH.EMAIL)(email)) || email === '';
    },
    
    validatePhone: (phone)=>{
        return (v.maxLength(TEAM.MAX_LENGTH.PHONE)(phone) && v.isNumber(phone)) || phone === '';
    },
    
    validateCountryCode:(countryCode)=>{
        return (v.maxLength(TEAM.MAX_LENGTH.COUNTRY_CODE)(countryCode) && v.isNumber(countryCode)) || countryCode === '';
    },
    
    validateDiscord: (val)=>{
        return (v.maxLength(TEAM.MAX_LENGTH.DISCORD_ID)(val)) || val === '';
    },

    validateTeamName: (val) => {
        return (v.maxLength(TEAM.MAX_LENGTH.NAME)(val)) || val === '';
    },

    validatePlayerName: (val) => {
        return (v.maxLength(TEAM.MAX_LENGTH.PLAYER_NAME)(val)) || val === '';
    },

    validateImageFile: (file) => {
        if(file){
            return (file.type.toLowerCase().startsWith('image'));
        } else {
            return (file==='');
        }
    },

    validatePlayerIGID: (val) => {
        return (v.maxLength(TEAM.MAX_LENGTH.IGID)(val)) || val === '';
    }
}

export const dialogQueue = createDialogQueue();

/**
 * @returns {firebase.firestore.Timestamp}
 */
export function parseTimeStamp({seconds, nanoseconds, _seconds, _nanoseconds}){
    if(seconds)
    return new firebase.firebase.firestore.Timestamp(seconds,nanoseconds);
    else 
    return new firebase.firebase.firestore.Timestamp(_seconds,_nanoseconds);
}

export function getPointSystemError(val){
    let pointSystem = val.replace(/\s/g,'').split(',');
    let points = [], i = 0, dp = {},a,b,max=-1,flag= [];
    try {
        pointSystem.forEach( (el) => {
              if(!el)
              return;
            let p = el.split('=');
            if( ! (v.isInteger(p[1]) && v.isRange(p[0]) ) ){
                const err =  new Error("Invalid Format. Use only numbers (1-100), '-' , '=' and ','\ne.g.\n1 = 20,\n2 = 14,\n3-5 = 10");
                throw err;
            }
            if(p[1].length>TOURNAMENT.MAX_LENGTH.POINTS){
                const err =  new Error("Points entered too large: "+p[1]);
                throw err;
            }

            if(p[0].indexOf('-') !== -1){
                [a,b] = p[0].split('-');
                a = parseInt(a)-1;
                b = parseInt(b)-1;
            } else {
                a = b = parseInt(p[0]) - 1;
            }
            if(a < 0 || b < 0 || a > 99 || b > 99){
                const err =  new Error("Ranks must be in the range 1 - 100. In '"+el+"'. ");
                throw err; 
            }

            if(a > b){
                const err =  new Error("Give range in ascending order. In '"+el+"'. ");
                throw err; 
            }
            for(;a<=b;a++){
               if(dp[a]){
                const err =  new Error("Rank "+(a+1)+" is repeated. ");
                throw err;
               } else {
                   dp[a] = true;
                   points[a] = p[1]
               }
            }
              max = max < b ? b : max;
        });
          for( i = 0 ; i <= max ; i++ ){
          if(!dp[i]){
            flag.push(i+1);
          }
        }
          if(flag.length){
          const err =  new Error("Ranks missing: "+flag.join(",")+". ");
          throw err;
        }
    }catch(err) {
        return err.message;
    }
    return 'Error';
}

export function getPointSystem(data){
    let pointSystem = data.split(',')
    let points = [],a,b;
    pointSystem.forEach( (el, index) => {
        if(!el)
            return;
        el=el.replace(/\s/g,'');
        let p = el.split('=');
        if(p[0].indexOf('-') !== -1){
            [a,b] = p[0].split('-');
            a = parseInt(a)-1;
            b = parseInt(b)-1;
        } else {
            a = b = parseInt(p[0]) - 1;
        }
        for(;a<=b;a++){
            points[a] = parseInt(p[1]);
        }
    });
    return points;
}

export function stringifyPointSystem(PS){
    if(PS.length === 0)
    return null;
    let ranges=[],prevRank = 1, prevPoint = PS[0],currRange = prevRank+" = "+prevPoint;
    for(let i=1;i<PS.length;i++){
        if(prevPoint === PS[i]){
            currRange = prevRank + " - " + (i+1) + " = " + prevPoint;
        }
        else {
            ranges.push(currRange);
            prevRank = i+1;
            prevPoint = PS[i];
            currRange = prevRank+" = "+prevPoint;
        }
    }
    if(ranges[ranges.length - 1] !== currRange){
        ranges.push(currRange);
    }
    return ranges.join(',\n');
}

export function verifyNewMatch(M){
    let flag,map=M.map;
    flag=false;
    gameMaps.forEach(G=>{
        G.maps.forEach(MAP=>{
            if(MAP.value === map){
                flag=true;
            }
        });
    });
    if(!flag){
        return null;
    }
    else return M;
}
//also edit in functions

export const copy = async (val) => {
    const el=document.createElement('input');
    el.setAttribute('type','text');
    el.value = val;
    el.style.opacity = 0.1;
    document.body.insertAdjacentElement('beforeend',el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}
/**
 * 
 * @param {String} dataURI
 * @returns {Blob} 
 */
export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    var ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
}
/**
 * 
 * @param {Object} exportObj 
 * @param {String} exportName 
 */
export function downloadObjectAsJson(exportObj, exportName){
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

export function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }
    console.log(str);
    return str;
}

export const downloadString = (dataStr,fileName) => {
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", fileName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

export const createCSVStringForTotalStandings = (standings = []) => {
    const replacer = (key, value) => value === null || value === undefined ? '' : value // specify how you want to handle null values here
    const header = ["name","matchesPlayed","wwcd","placePoint","killPoint","totalPoint"]
    let csv = standings.map((row,i )=> (i+1)+','+header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift('Rank,'+header.join(','))
    csv = csv.join('\r\n')
    return "data:text/csv;charset=utf-8,"+csv;
}

export const createCSVStringForKillsStandings = (standings = []) => {
    const replacer = (key, value) => value === null || value === undefined ? '' : value // specify how you want to handle null values here
    const header = ["name","killPoint"]
    let csv = standings.map((row,i )=> (i+1)+','+header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift('Rank,'+header.join(','))
    csv = csv.join('\r\n')
    return "data:text/csv;charset=utf-8,"+csv;
}

export const createCSVStringForPlaceStandings = (standings = []) => {
    const replacer = (key, value) => value === null || value === undefined ? '' : value // specify how you want to handle null values here
    const header = ["name","placePoint",]
    let csv = standings.map((row,i )=> (i+1)+','+header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift('Rank,'+header.join(','))
    csv = csv.join('\r\n')
    return "data:text/csv;charset=utf-8,"+csv;
}

export const createCSVStringForKillLeaderboard = (standings = []) => {
    const replacer = (key, value) => value === null || value === undefined ? '' : value // specify how you want to handle null values here
    const header = ["name","team","kills"]
    let csv = standings.map((row,i )=> (i+1)+','+header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift('Rank,'+header.join(','))
    csv = csv.join('\r\n')
    return "data:text/csv;charset=utf-8,"+csv;
}

export const createCSVStringForMatch = (standings = []) => {
    const replacer = (key, value) => value === null || value === undefined ? '' : value // specify how you want to handle null values here
    const header = ["name","place","placePoint","killPoint","matchPoint"]
    let csv = standings.map((row,i )=> (i+1)+','+header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift('Rank,'+header.join(','))
    csv = csv.join('\r\n')
    return "data:text/csv;charset=utf-8,"+csv;
}

export const getMatchWithNamesAndSort = (match,teams=[], killMode = TOURNAMENT.KILLMODE.TEAM) => {
    const newMatch = JSON.parse(JSON.stringify(match));
    newMatch.points = [];
    match.points.forEach(point => {
        const team = teams.find(team => team.id === point.teamId);
        if(!team)
            return;
        const newPoint = {
            teamId: point.teamId,
            kills: point.kills,
            place: point.place,
            name: team.name,
            playerKills: []
        };
        if(killMode === TOURNAMENT.KILLMODE.PLAYER ){
            point.playerKills.forEach(player => {
                const playerData = team.playerList.find(P => P.id  === player.id);
                newPoint.playerKills.push({
                    id: player.id,
                    kills: player.kills,
                    name: playerData ? playerData.name : "----"
                });
            });
        }
        newMatch.points.push(newPoint);
    });
    newMatch.points.sort((a,b)=> a.place - b.place);
    return newMatch;
}

export const getMatchRemoveExtra = (match,killMode = TOURNAMENT.KILLMODE.TEAM) => {
    const newMatch = JSON.parse(JSON.stringify(match));
    newMatch.points.forEach(point => {
        const newPoint = {
            teamId: point.teamId,
            kills: point.kills,
            place: point.place,
            playerKills: []
        };
        if(killMode === TOURNAMENT.KILLMODE.PLAYER ){
            point.playerKills.forEach(player => {
                newPoint.playerKills.push({
                    id: player.id,
                    kills: player.kills,
                });
            });
        }
        point = newPoint;
    });
    newMatch.points.sort((a,b)=> a.place - b.place);
    return newMatch;
}