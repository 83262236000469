import { REFRESHING_TOKEN, UPDATE_USER_CREDIT_INFO, USER_SIGNED_IN, USER_SIGNED_OUT, USER_SIGN_IN_ERR } from "./userActionTypes";

const initialState = {
    user: null,
    errMess: null,
    checkingAuth: true,
    refreshingToken: false,
    userCreditsInfo: {
        credits: 0
    }
};

export default function User(state=initialState,action){
    const newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case USER_SIGNED_IN:
            newState.user = action.payload;
            newState.errMess = null;
            newState.checkingAuth = false;
            newState.refreshingToken = false;
            return newState;
        case USER_SIGNED_OUT:
            newState.user = null;
            newState.errMess = null;
            newState.checkingAuth = false;
            newState.refreshingToken = false;
            return newState;
        case USER_SIGN_IN_ERR:
            newState.user = null;
            newState.errMess = action.payload;
            newState.checkingAuth = false;
            newState.refreshingToken = false;
            return newState;
        case REFRESHING_TOKEN:
            newState.refreshingToken = action.payload;
            return newState;
        case UPDATE_USER_CREDIT_INFO:
            newState.userCreditsInfo = action.payload;
            return newState;
        default:
            return state;
    }
}