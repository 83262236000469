import firebase from 'firebase/app';

export const TEAM = {
    LOGO_MAX_DIMENSION: 150,
    PLAYER_LOGO_MAX_DIMENSION: 150,
    MAX_LENGTH: {
        NAME: 50,
        PLAYER_NAME: 30,
        PHONE: 12,
        DISCORD_ID: 32,
        EMAIL: 30,
        COUNTRY_CODE: 5,
        IGID: 15,
    }
}

export default class Team {
    constructor({
        name,
        playerList = [],
        fromForm = false,
        email = null,
        phone = null,
        countryCode = null,
        discord = null,
        logo = null,
        id = null,
        createdAt = null
    }){
        this.name = name;
        this.playerList = playerList;
        this.fromForm = fromForm;
        this.email = email;
        this.phone = phone;
        this.countryCode = countryCode;
        this.discord = discord;
        this.logo = logo;
        this.id = id;
        if(createdAt){
            const timestamp = new firebase.firestore.Timestamp(createdAt.seconds,createdAt.nanoseconds);
            this.createdAt = timestamp;
        } else {
            this.createdAt = firebase.firestore.Timestamp.now();
        }
    }
}

export const teamConverter = {
    toFirestore: function(team) {
        return {
            name: team.name,
            playerList: team.playerList,
            fromForm: team.fromForm,
            email: team.email,
            phone: team.phone,
            countryCode: team.countryCode,
            discord: team.discord,
            logo: team.logo,
            createdAt: team.createdAt
            }
    },
    fromFirestore: function(snapshot, options){
        const data = snapshot.data(options);
        const T = new Team(data);
        T.id = snapshot.id;
        return T;
    }
}