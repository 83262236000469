import firebase from '../Components/firebase/firebase';

export const TOURNAMENT = {
    COMPLETED: "COMPLETED",
    UPCOMING: "UPCOMING",
    ONGOING: "ONGOING",
    TYPE: ['SOLO','DUO','SQUAD'],
    KILLMODE: {
        TEAM: 'TEAM',
        PLAYER: 'PLAYER'
    },
    TIE_PREF: {
        KILL: "kills",
        PLACE: "place"
    },
    MAX_LENGTH: {
        NAME: 80,
        POINTS: 5,
        PRICE: 50,
        ORGANISER: 80
    },
    LOGO_MAX_DIMENSION: 512,
    BANNER_MAX_DIMENSION: 1024,
}

export default class Tournament {
    constructor({
        name,
        type,
        teams = [],
        tiePref = TOURNAMENT.TIE_PREF.KILL,
        pointSystem = {
            placePoint: [],
            killPoint: 0
        },
        formRef = null,
        publicPostRef = null,
        matches = [],
        killMode,
        status = TOURNAMENT.UPCOMING,
        createdAt = null,
        id = null,
        logo = null,
        teamLoaded = false,
        banner = null,
        price = '',
        isPublished = false,
        owner = null,
        organiser = '',
        contactItems = [],
        contactDataLoaded = false
    }){
        this.name = name;
        this.type = type;
        this.teams = teams;
        this.pointSystem = {
            killPoint: parseInt(pointSystem.killPoint),
            placePoint: pointSystem.placePoint
        };
        this.formRef = formRef;
        this.publicPostRef = publicPostRef;
        this.matches = matches;
        this.killMode = killMode;
        this.status = status;
        if(createdAt){
            let timestamp;
            if(createdAt.seconds)
                timestamp = new firebase.firebase.firestore.Timestamp(createdAt.seconds,createdAt.nanoseconds);
            else 
                timestamp = new firebase.firebase.firestore.Timestamp(createdAt._seconds,createdAt._nanoseconds);
            this.createdAt = timestamp;
        } else {
            this.createdAt = firebase.firebase.firestore.Timestamp.now();
        }
        this.id = id;
        this.logo = logo;
        this.teamLoaded = teamLoaded;
        this.banner = banner;
        this.price = price;
        this.isPublished = isPublished;
        this.owner = owner;
        this.organiser = organiser;
        this.tiePref = tiePref;
        this.contactItems = contactItems;
        this.contactDataLoaded = contactDataLoaded;
    }
    setId(id){
        this.id = id;
    }
}

export const tournamentConverter = {
    toFirestore: function(tourney) {
        return {
            name: tourney.name,
            type: tourney.type,
            pointSystem: tourney.pointSystem,
            formRef: tourney.formRef,
            publicPostRef: tourney.publicPostRef,
            killMode: tourney.killMode,
            status: tourney.status,
            createdAt: tourney.createdAt,
            logo: tourney.logo ,
            banner: tourney.banner,
            price: tourney.price,
            isPublished: tourney.isPublished,
            owner: tourney.owner,
            organiser: tourney.organiser,
            tiePref: tourney.tiePref
        }
    },
    fromFirestore: function(snapshot, options){
        const tourney = snapshot.data(options);
        return new Tournament({
            name: tourney.name,
            type: tourney.type,
            pointSystem: tourney.pointSystem,
            formRef: tourney.formRef,
            publicPostRef: tourney.publicPostRef,
            killMode: tourney.killMode,
            status: tourney.status,
            createdAt: tourney.createdAt,
            id: snapshot.id,
            logo: tourney.logo,            
            banner: tourney.banner,
            price: tourney.price,
            isPublished: tourney.isPublished,
            owner: tourney.owner,
            organiser: tourney.organiser,
            tiePref: tourney.tiePref
        });
    }
}