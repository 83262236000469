import { REFRESHING_TOKEN, UPDATE_USER_CREDIT_INFO, USER_SIGNED_IN, USER_SIGNED_OUT, USER_SIGN_IN_ERR } from "./userActionTypes";

export const userSignedIn = (user) => ({
    type: USER_SIGNED_IN,
    payload: user
})

export const userSignedOut =  ()=>({
    type: USER_SIGNED_OUT
})

export const userSignInError = (errMess) => ({
    type: USER_SIGN_IN_ERR,
    payload: errMess
})

export const verifySignIn = userData => dispatch => {

} 

export const setRefreshingToken = isRefreshing => {
    return {
        type: REFRESHING_TOKEN,
        payload: isRefreshing
    }
}

export const updateUserCredit = (creditInfo) => {
    return {
        type: UPDATE_USER_CREDIT_INFO,
        payload: creditInfo
    }
}