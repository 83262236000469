import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { APP_NAME, APP_URL } from '../../Configs/appConfig';
import { Icon } from '@rmwc/icon';
import twitter from '../../Assets/Icons/twitter.svg';
import github from '../../Assets/Icons/github.svg';
import linkedin from '../../Assets/Icons/linkedin.svg';
import facebook from '../../Assets/Icons/facebook.svg';
import email from '../../Assets/Icons/email.svg';
import { Typography } from '@rmwc/typography';
class DevInfo extends React.Component {
    shouldComponentUpdate(){
        return false;
    }
    render(){
        return (
            <div className="dev-info" >
                <Container>
                    <Row className="mb-0 p-5">
                        <Col xs={12} md={5} className="d-flex align-items-center justify-content-center">
                            <img className="dev-image rounded-circle" style={{backgroundColor: "steelblue"}} src={`${APP_URL}/images/me.webp`} alt="Abhinav Kumar"></img>
                        </Col>
                        <Col xs={12} md={7} className="d-flex align-items-center justify-content-center text-center lead dev-text">
                        <div>
                            <Row>
                            <Typography use="body1">Hi, I am Abhinav, the creator and maintainer of {APP_NAME}. I am a full stack web developer, blogger and a competitve programmer. Send me message on any of my social media handle. I would love to connect with you all.</Typography>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <a href="http://www.facebook.com/avitechlab" className="p-1">
                                    <Icon icon={{icon: facebook, size: "large"}}/>
                                </a>
                                <a href="https://www.linkedin.com/in/abhinavkrin/" className="p-1">
                                    <Icon icon={{icon: linkedin, size: "large"}}/>
                                </a>
                                <a href="http://twitter.com/abhinavkr_in" className="p-1">
                                    <Icon icon={{icon: twitter, size: "large"}}/>
                                </a>
                                <a href="https://github.com/abhinavkrin" className="p-1">
                                    <Icon icon={{icon: github, size: "large"}}/>
                                </a>
                                <a href="mailto:contact@esportsweb.in" className="p-1">
                                    <Icon icon={{icon: email, size: "large"}}/>
                                </a>
                            </Row>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default DevInfo;