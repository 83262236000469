import { REGISTERED_TEAM, REGISTERING_TEAM, REGISTERING_TEAM_ERROR, RESET_REGISTERED_TEAM } from "../Tournaments/tournamentsActionTypes";

const { default: Team } = require("../../Models/Team");

const initialState = {
    registered: false,
    team: new Team({}),
    registeringTeam: false,
    registeringTeamError: null
}

export default function Registration(state = initialState, action){
    const newState =  JSON.parse(JSON.stringify(state));
    switch(action.type){
        case REGISTERING_TEAM:
            newState.registered = false;
            newState.registeringTeam = true;
            newState.registeringTeamError = null;
            return newState;
        
        case REGISTERING_TEAM_ERROR:
            newState.registered = false;
            newState.registeringTeam = false;
            newState.registeringTeamError = action.payload;
            return newState;
        
        case REGISTERED_TEAM:
            newState.registered = true;
            newState.team = action.payload;
            newState.registeringTeam = false;
            newState.registeringTeamError = null;
            return newState;

        case RESET_REGISTERED_TEAM:
            return initialState;
            
        default:
            return state;
    }
}