exports.gameMaps = [{
    game: "Others",
    display: "Others",
    maps: [{
        display: 'NONE',
        value: 'NONE'
    }]
},{
    game: "PUBG",
    display: "PUBG (PC & Mobile)",
    maps: [{
        display: "ERANGEL",
        value: "ERANGEL"
    },{
        display: "SANHOK",
        value: "SANHOK"
    },{
        display: "MIRAMAR",
        value: "MIRAMAR"
    },{
        display: "VIKENDI",
        value: "VIKENDI"
    },{
        display: "LIVIK",
        value: "LIVIK"
    },{
        display: "ALL WEAPONS",
        value: "ALLWEAPONS"
    }]
},{
    game: "PUBGLITE",
    display: "PUBG LITE (Mobile)",
    maps: [{
        display: "VARENGA",
        value: "VARENGA"
    },{
        display: "GOLDEN WOODS",
        value: "GOLDENWOODS"
    }]
},{
    game: "FREE FIRE",
    display: "FREE FIRE",
    maps: [{
        display: "BERMUDA",
        value: "BERMUDA"
    },{
        display: "KALAHARI",
        value: "KALAHARI"
    },{
        display: 'PURGATORY',
        value: 'PURGATORY'
    }]
},{
    game: "COD Mobile",
    display: "COD Mobile",
    maps: [{
        display: 'CLASSIC',
        value: 'CLASSIC'
    }]
}]