export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_TAGLINE = process.env.REACT_APP_APP_TAGLINE;
export const ORGANISATION = process.env.REACT_APP_ORGANISER;
export const APP_URL = document.location.origin;

//also edit in functions
export const DB = {
    name: 'tournifycms',
    TOURNAMENTS: "wTournaments",
    TEAMS: "wTeams",
    REGISTRATION_FORMS: "wRegistrationForms",
    RESPONSES: "wResponses",
    MATCHES: "wMatches",
    PUBLIC_POSTS: "wPublicPosts",
    ADMINS: 'wAdmins',
    PRODUCTS: "wProducts",
    COMMON: "common",
    MEMBERSHIP_OFFER: "membershipOffer",
    TOURNAMENT_OTHER_DATA: "wOtherData",
    TOURNAMENT_CONTACT_DATA: "contactData",
    USERS: "wUsers"
}

export const STORAGE = {
   TOURNAMENTS: "wTournaments",
   TOURNAMENT_LOGO: "logo.webp",
   TOURNAMENT_LOGO_META: {
        cacheControl: 'public,max-age=6048001'
   },
   TOURNAMENT_BANNER: "banner.webp",
   TOURNAMENT_BANNER_META: {
        cacheControl: 'public,max-age=6048001'
   },
   TEAMS: "wTeams",
   TEAM_LOGO: "logo.webp",
   TEAM_IMAGE_META: {
        cacheControl: 'public,max-age=6048001'
   },
   MEDIA: "wMedia",
   MEDIA_META : {
        cacheControl: 'public,max-age=6048001'
   }
}


export const STORAGE_REF = {
    getTourneyRef: (id,uid)=> uid+'/'+STORAGE.TOURNAMENTS+'/'+id,
    getTeamRef: (id,teamId,uid) => uid+'/'+STORAGE.TOURNAMENTS+'/'+id+'/'+STORAGE.TEAMS+'/'+teamId,
    getPlayerImageName: (i) => "player"+i+".webp",
    getMediaRef: () => STORAGE.MEDIA
}

export const DEMO_TOURNAMENT_ID = "o3m2JR8DO0WgcTUe9eyk";
export const DEMO_REGFORM_ID = "ZHEflOiDm62i3SYLPjvM";

export const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;