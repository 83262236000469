import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import UserReducer from './User/UserReducer';
import {createForms} from 'react-redux-form'
import { newTournament , basicTournamentSettings, newTeam, pointsTableSettings, newMatch, newFormSettings} from './Forms/FormInitState';
import tournamentReducer from './Tournaments/TournamentReducer';
import Registration from './Registration/registrationReducer';
import StatsReducer from './Stats/StatsReducer';
import Public from './Public/Public';
import { CustomTheme } from './CustomTheme/CustomTheme';
export const configureStore = () => {
    const store = createStore(
        combineReducers({
            user: UserReducer,
            tournaments: tournamentReducer,
            registration: Registration,
            stats: StatsReducer,
            ...createForms({
                newTournament,
                basicTournamentSettings,
                newTeam,
                pointsTableSettings,
                newMatch,
                newFormSettings
            }),
            public: Public,
            theme: CustomTheme
        }),
        applyMiddleware(thunk)
    );
    return store;
}