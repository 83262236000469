import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import Analytics from './analytics';
import { firebaseConfig } from '../../Configs/firebaseConfig';

class Firebase {
  initialize(){
    firebase.initializeApp(firebaseConfig);
    this.firebase = firebase;
    firebase.analytics();
    const analytics = new Analytics(firebase.analytics);
    this.auth = firebase.auth;
    this.analytics = analytics;
    this.storage = firebase.storage();
    this.db = firebase.firestore();
    this.functions = firebase.functions();
  }
}

export default new Firebase();