import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import  {pages} from './Pages';
import { HOME } from '../Configs/routes';
import ProtectedRoute from './Auth/ProtectedRoute';
class MainComponent extends React.Component {
    render(){
        return (
            <React.Fragment>
                <Switch>
                    {pages.map((page,i)=>(
                        page.auth ?
                        <ProtectedRoute path={page.path} key={i+page.path} exact={page.exact} component={(props) => page.component(props)}/>
                            :
                        <Route path={page.path} key={i+page.path} exact={page.exact} render={(props)=>page.component(props)}/>
                    ))}
                    <Redirect to={HOME}/>
                </Switch>
            </React.Fragment>
        );
    }
}

export default withRouter(MainComponent);