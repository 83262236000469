import React from 'react';
import { Icon } from '@rmwc/icon';

export default function FeatureItem({title,body,icon}){
    return (
        <div className="feature-item">
            <div className="text-primary d-flex justify-content-center">
                <Icon icon={{icon, size: "xlarge"}}/>
            </div>
            <h3>{title}</h3>
            <p className="text-muted">{body}</p>
        </div>
    )
}