export const CREATING_TOURNAMENT = "CREATING_TOURNAMENT";
export const CREATE_TOURNAMENT_ERROR = "CREATE_TOURNAMENT_ERROR";
export const CREATED_TOURNAMENT = "CREATED_TOURNAMENT";

export const UPDATED_TOURNAMENT = "UPDATED_TOURNAMENT";
export const UPDATING_TOURNAMNT = "UPDATING_TOURNAMNT";
export const UPDATING_TOURNAMNT_ERROR = "UPDATING_TOURNAMNT_ERROR";

export const LOADING_TOURNAMENTS = "LOADING_TOURNAMENTS";
export const LOADING_TOURNAMENTS_ERROR = "LOADING_TOURNAMENTS_ERROR";
export const LOADED_TOURNAMENTS = "LOADED_TOURNAMENTS";
export const TOURNAMENTS_END_REACHED = "TOURNAMENTS_END_REACHED";
export const DELETED_TOURANAMENT = "DELETED_TOURANAMENT";

export const LOADING_ACTIVE_TOURNAMENT = "LOADING_ACTIVE_TOURNAMENT";
export const LOADING_ACTIVE_TOURNAMENT_ERROR = "LOADING_ACTIVE_TOURNAMENT_ERROR";
export const LOADED_ACTIVE_TOURNAMENT = "LOADED_ACTIVE_TOURNAMENT";

export const SET_TOURNAMENT_LOGO = "SET_TOURNAMENT_LOGO";
export const SETTING_TOURNAMENT_LOGO = "SETTING_TOURNAMENT_LOGO";
export const SETTING_TOURNAMENT_LOGO_ERROR = "SETTING_TOURNAMENT_LOGO_ERROR";

export const SET_TOURNAMENT_BANNER = "SET_TOURNAMENT_BANNER";
export const SETTING_TOURNAMENT_BANNER = "SETTING_TOURNAMENT_BANNER";
export const SETTING_TOURNAMENT_BANNER_ERROR = "SETTING_TOURNAMENT_BANNER_ERROR";

export const SAVING_TOURNAMENT_CONTACT_DATA = "SAVING_TOURNAMENT_CONTACT_DATA";
export const SAVED_TOURNAMENT_CONTACT_DATA = "SAVED_TOURNAMENT_CONTACT_DATA";

export const CREATING_TEAM = "CREATING_TEAM";
export const CREATING_TEAM_ERROR = "CREATE_TOURNAMENT_ERROR";
export const CREATED_TEAM = "CREATED_TEAM";
export const RESET_CREATED_TEAM = "RESET_CREATED_TEAM";
export const DELETED_TEAM = "DELETED_TEAM";
export const SAVING_TEAM = "SAVING_TEAM";
export const SAVED_TEAM  = "SAVED_TEAM";

export const SAVING_POINTS_SETTING = "SAVING_POINTS_SETTING";
export const SAVED_POINTS_SYSTEM = "SAVED_POINTS_SYSTEM";

export const SAVING_MATCH = "SAVING_MATCH";
export const SAVING_MATCH_ERROR = "SAVING_MATCH_ERROR";
export const SAVED_MATCH = "SAVED_MATCH";
export const SAVED_MATCH_RESET = "SAVED_MATCH_RESET";
export const DELETED_MATCH = "DELETED_MATCH";
export const UPDATING_MATCH = "UPDATIG_MATCH";
export const UPDATING_MATCH_ERROR = "UPDATING_MATCH_ERROR";
export const UPDATED_MATCH = "UPDATED_MATCH";

export const LOADING_FORM_SETTINGS =  "LOADING_FORM_SETTINGS";
export const LOADED_FORM_SETTINGS = "LOADED_FORM_SETTINGS";
export const LOADING_FORM_SETTINGS_ERROR = "LOADING_FORM_SETTINGS_ERROR";
export const LOADED_FORM_SETTINGS_BY_ID = "LOADED_FORM_SETTINGS_BY_ID";

export const SAVING_FORM_SETTINGS = "SAVING_FORM_SETTINGS";
export const SAVED_FORM_SETTINGS = "SAVED_FORM_SETTINGS";

export const TOGGLING_FORM_STATUS = "TOGGLING_FORM_STATUS";
export const TOGGLED_FORM_STATUS = "TOGGLED_FORM_STATUS";

export const REGISTERING_TEAM = "REGISTERING_TEAM";
export const REGISTERING_TEAM_ERROR = "REGISTERING_TEAM_ERROR";
export const REGISTERED_TEAM = "REGISTERED_TEAM";
export const RESET_REGISTERED_TEAM = "RESET_REGISTERED_TEAM";

export const APPROVED_RESPONSE = "APPROVED_RESPONSE";
export const RESPONSE_REMOVED = "RESPONSE_REMOVED";