import * as firebaseui from 'firebaseui';
import React, {Component} from 'react';
import {CircularProgress} from '@rmwc/circular-progress';
import {Elevation} from '@rmwc/elevation';
import firebase from './firebase';
import { connect } from 'react-redux';
import {Typography} from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { PRIVACY, TERMS_OF_USE } from '../../Configs/routes';
function mapStateToProps(state){
  return {
    errMess: state.user.errMess,
    refreshingToken: state.user.refreshingToken
  }
}
class SignInFrom extends Component{
    start(){
      this.ui.start('#firebaseui-auth-container', this.uiConfig);  
    }
    constructor(props){
        super(props);
        this.state = {
          showLoader: true
        }
        this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        this.uiConfig = {
            callbacks: {
              signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                return false;
              },
              uiShown: () => {
                // The widget is rendered.
                // Hide the loader.
                //document.getElementById('loader').innerHTML = '';
                this.setState({showLoader:false})
              }
            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'popup',
            signInSuccessUrl: '<url-to-redirect-to-on-success>',
            signInOptions: [
              // Leave the lines as is for the providers you want to offer your users.
              {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
              },
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
            tosUrl: window.location.origin+TERMS_OF_USE,
            privacyPolicyUrl: window.location.origin+PRIVACY       
           };  
    }

    componentDidMount(){
      document.body.insertAdjacentHTML('beforeend','<link type="text/css" rel="stylesheet" href="https://cdn.firebase.com/libs/firebaseui/3.5.2/firebaseui.css" />');
      this.start();
    }

    render(){
        return (
          <div className="d-flex justify-content-center mb-3 sign-in-container">
            <Elevation z={5} wrap>
              <div className="d-flex sign-in-card flex-column">
                <div className="sign-in-title text-center">
                  <Typography use="headline6">
                    Sign in to continue
                  </Typography>
                </div>
                <div className="sign-in-form">
                  {(this.props.refreshingToken || this.state.showLoader ) &&
                    <div className="sign-in-form-overlay">
                      <CircularProgress size="large"/>
                    </div>
                  }
                  <div id={"firebaseui-auth-container"}></div>
                </div>
                <div className="sign-in-error p-2">
                    <Typography use="body1" className="text-danger">
                      {this.props.errMess}
                    </Typography>
                </div>
                {this.props.errMess && <div className="d-flex justify-content-center p-2">
                    <Button outlined onClick={()=> {firebase.auth().signOut(); this.start();}}>LOG OUT</Button>
                </div>}
              </div>
            </Elevation>
          </div>
        );
    }
}

export default connect(mapStateToProps)( SignInFrom)