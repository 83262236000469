import React from 'react';
import {TopAppBar, TopAppBarSection, TopAppBarRow, TopAppBarFixedAdjust, TopAppBarTitle, TopAppBarNavigationIcon} from '@rmwc/top-app-bar';
import {Drawer, DrawerHeader, DrawerContent} from '@rmwc/drawer';
import {Button} from '@rmwc/button'
import {List, ListItem} from '@rmwc/list';
import { APP_NAME} from '../Configs/appConfig';
import * as routes from '../Configs/routes';
import {Link, withRouter, matchPath} from 'react-router-dom';
import firebase from './firebase/firebase';
import { connect } from 'react-redux';
import { CircularProgress } from '@rmwc/circular-progress';
import { Badge } from '@rmwc/badge';
import { Dialog, DialogContent, DialogTitle } from '@rmwc/dialog';
import { copy, dialogQueue } from '../functions/common';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

function mapStateToProps(state){
    return {
        user: state.user.user
    }
}

class Header extends React.Component {
    state = {
        sideNavOpen: false,
        redirectingToPortal: false
    }
    toggleSideNav(open){
        this.setState(prev=>({
            ...prev,
            sideNavOpen: open
        }))
    }

    render(){
        return (
            <React.Fragment>
                {this.state.redirectingToPortal &&
                    <Dialog
                        preventOutsideDismiss
                        open={this.state.redirectingToPortal}
                        >
                        <DialogTitle className="d-flex justify-content-center">
                            Please wait...
                        </DialogTitle>
                        <DialogContent className="d-flex justify-content-center">
                            <CircularProgress size="xlarge"/>
                        </DialogContent>
                    </Dialog>
                }
                <Drawer style modal open={this.state.sideNavOpen} onClose={() => this.toggleSideNav(false)}>
                    <DrawerHeader>
                        
                        <div className="w-100 d-flex justify-content-center align-items-center flex-column p-2">
                            <img 
                                style={{width: "128px", height: "128px"}}
                                src={this.props.user && this.props.user.photoURL ? this.props.user.photoURL : window.location.origin+'/logo192.webp'}
                                className='img-fluid rounded-circle'
                                alt={APP_NAME}
                            />
                            {this.props.user && this.props.user.subscription &&
                                <Link to={routes.ACCOUNT}>
                                    <div style={{cursor: "pointer"}}>
                                        <Badge style={{background:"var(--mdc-theme-secondary)"}} align="inline" label="Membership"/>
                                    </div>
                                </Link>
                            }
                        </div>
                        <Link to={routes.HOME} className="link-unstyled">                            
                            <Typography use="headline6" style={{color: "var(--mdc-theme-on-surface)"}}>
                                {APP_NAME}
                            </Typography>
                        </Link>
                        { 
                            this.props.user &&
                            <div className="w-100 mt-2" style={{fontSize: "12px"}}>
                                <ul className="list-unstyled">
                                    <li><b>email:</b></li>
                                    <li>{this.props.user.email}</li>
                                    <li><b>user ID:</b></li>
                                    <li onClick={() => 
                                        copy(this.props.user.uid)
                                        .then(()=> 
                                            dialogQueue.alert({
                                                title: "Copied", 
                                                body: this.props.user.uid+" is copied."
                                            })
                                        )} 
                                        style={{cursor: "pointer"}}
                                        className="d-flex align-items-center">
                                        <small>{this.props.user.uid}</small>
                                        <Icon icon={{icon:"content_copy", size: "xsmall" }}  />
                                    </li>
                                </ul>
                            </div>
                        }
                    </DrawerHeader>
                    <DrawerContent>
                    <List>
                        <Link to={routes.HOME} key={routes.HOME} className="link-unstyled">
                            <ListItem activated={matchPath(this.props.match.path,{
                                path: routes.HOME,
                                exact: true
                            })}>
                                Home
                            </ListItem>
                        </Link>
                        { 
                            this.props.user &&
                            <a href={"https://beta.esportsweb.in/account"} className="link-unstyled">
                                <ListItem activated={matchPath(this.props.match.path,{
                                        path: routes.ACCOUNT,
                                        exact: true
                                })}>
                                    My Account
                                </ListItem>
                            </a>
                        }
                        <Link to={routes.TOURNAMENTS} key={routes.TOURNAMENTS} className="link-unstyled">
                            <ListItem activated={matchPath(this.props.match.path,{
                                path: routes.TOURNAMENTS,
                                exact: true
                            })}>
                                Find Tournaments
                            </ListItem>
                        </Link>
                        <Link to={routes.ADMIN} key={routes.ADMIN} className="link-unstyled">
                            <ListItem activated={matchPath(this.props.match.path,{
                                path: routes.ADMIN,
                                exact: true
                            })}>
                                Dashboard
                            </ListItem>
                        </Link>
                        <Link to={routes.PRICING} key={routes.PRICING} className="link-unstyled">
                            <ListItem activated={matchPath(this.props.match.path,{
                                path: routes.PRICING,
                                exact: true
                            })}>
                                Plans & Pricing
                            </ListItem>
                        </Link>
                        { 
                            this.props.user && this.props.user.superadmin &&
                            <Link to={routes.ADMIN_PANEL} key={routes.ADMIN_PANEL} className="link-unstyled">
                                <ListItem activated={matchPath(this.props.match.path,{
                                    path: routes.ADMIN_PANEL,
                                    exact: true
                                })}>
                                    Admin Panel
                                </ListItem>
                            </Link>
                        }
                        { 
                            this.props.user ?
                                <div className="d-flex justify-content-center p-3" style={{width: "100%"}}>
                                    <Button onClick={()=> firebase.auth().signOut()} outlined>Log out</Button>
                                </div>
                                :
                                <div className="d-flex justify-content-center p-3" style={{width: "100%"}}>
                                    <Button onClick={()=> this.props.history.push(routes.ADMIN)} outlined>Log in</Button>
                                </div>
                        }
                    </List>
                    </DrawerContent>
                </Drawer>
                <TopAppBar onNav={()=>this.toggleSideNav(true)} fixed>
                    <TopAppBarRow> 
                        <TopAppBarSection alignStart>
                            <Link to={routes.HOME} className="link-unstyled text-white">
                                <TopAppBarTitle>                                
                                    <img 
                                        src={window.location.origin+'/logo.webp'}
                                        className='img-fluid'
                                        style={{maxWidth: "2rem", borderRadius: "5px", marginRight: "5px"}}
                                        alt={APP_NAME}
                                    />
                                    {APP_NAME}
                                </TopAppBarTitle>
                            </Link>
                        </TopAppBarSection>
                        <TopAppBarSection alignEnd>
                            {this.props.user && this.props.user.subscription &&
                                <Link to={routes.ACCOUNT}>
                                    <div style={{cursor: "pointer"}}>
                                        <Badge style={{background:"var(--mdc-theme-secondary)"}} align="inline" label="Membership"/>
                                    </div>
                                </Link>
                            }
                            <TopAppBarNavigationIcon icon="menu" />
                        </TopAppBarSection>
                    </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust />  
                
            </React.Fragment>      
        );
    }
}
export default withRouter(connect(mapStateToProps)(Header));