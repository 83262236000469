import * as actionTypes from './actionTypes';
export const initS = {
    bgType: "solid",
    bgSolid: "rgba(255,255,255,1)",
    bgGrad: "linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,1))",
    background: "none",
    fontSize: "14px",
    padding: "4px",
    textAlign: "left",
    fontColor: "rgba(0,255,0,1)",
    borderColor: "rgba(0,0,255,1)",
    borderWidth: "1px",
    borderRadius: {
        tl: "0px",
        tr: "0px",
        bl: "0px",
        br: "0px"
    }
}
export const initHeadCell = {
    bgType:"gradient",
    bgSolid:"rgba(255,255,255,1)",
    bgGrad:"linear-gradient(to bottom, rgba(0,0,0,1), rgba(74,74,74,1))",
    fontSize:"14px",
    padding:"12px",
    textAlign:"center",
    fontColor:"rgba(255,255,255,1)",
    borderWidth:"1px",
    borderColor:"rgba(255,255,255,1)",
    borderRadius:{
        tl:"10px",
        tr:"0px",
        bl:"0px",
        br:"10px"
    }
}
export const initTeamCell = {
    bgType:"gradient",
    bgSolid:"rgba(255,255,255,1)",
    bgGrad:"linear-gradient(to bottom, rgba(221,156,56,1), rgba(0,0,0,0.31))",
    fontSize:"14px",
    padding:"4px",
    textAlign:"center",
    fontColor:"rgba(255,255,255,1)",
    borderWidth:"2px",
    borderColor:"rgba(245,166,35,0.52)",
    borderRadius:{
        tl:"10px",
        tr:"0px",
        bl:"0px",
        br:"10px"
    }
}
export const initDataCell = {
    bgType:"gradient",
    bgSolid:"rgba(255,255,255,1)",
    bgGrad:"linear-gradient(to bottom, rgba(0,0,0,1), rgba(255,255,255,0.3))",
    fontSize:"14px",
    padding:"5px",
    textAlign:"center",
    fontColor:"rgba(255,255,255,1)",
    borderWidth:"2px",
    borderColor:"rgba(74,74,74,1)",
    borderRadius:{
        tl:"10px",
        tr:"0px",
        bl:"0px",
        br:"10px"
    }
}
export const initGeneralS = {
    customBg:null,
    title:"Overall Standings",
    topMargin:"0",
    showTournamentLogo:true,
    tournamentNameColor:"rgba(255,255,255,1)",
    tournamentOrganiserColor:"rgba(255,255,255,1)",
    titleColor:"rgba(221,156,56,1)"
}
export const initialTheme = {
    general: initGeneralS,
    teamHeadCell: initHeadCell,
    teamNameCell: initTeamCell,
    otherHeadCell: initHeadCell,
    otherDataCell: initDataCell
}

export const CustomTheme = (state = initialTheme, action) => {
    let newState;  

    switch(action.type){
        case actionTypes.SET_FIELD:
            newState = JSON.parse(JSON.stringify(state));
            newState[action.payload.field] = action.payload.data;
            return newState;

        case actionTypes.LOAD_THEME:
            newState = action.payload;
            return newState;
        default:
            return state;
    }
}