import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withRouter } from 'react-router-dom';
import SignInFrom from '../firebase/SignInForm';
import Header from '../Header';
import { Helmet } from 'react-helmet';
import { APP_NAME } from '../../Configs/appConfig';
import Footer from '../Footer';

function Login(){
    return (
        <>
        <Header/>
        <Helmet>
            <title>Log in | {APP_NAME}</title>
        </Helmet>
        <Container className="pt-2">
            <Row className="mt-2">
                <Col xs={12} className="d-flex justify-content-center">
                    <SignInFrom/>
                </Col>
            </Row>
        </Container>
        <Footer/>
        </>
    )
}

export default withRouter(Login);