import React from 'react';
import firebase from './firebase/firebase';
import { setRefreshingToken, userSignedIn, userSignedOut, userSignInError } from '../redux/User/userActionCreator';
import { connect } from 'react-redux';
import { PRIVACY, CONTACT, TERMS_OF_USE, DISCLAIMER, FAQ, ABOUT, HOME, TOURNAMENTS, ADMIN, PRICING, REFUND_POLICY } from '../Configs/routes';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Typography } from '@rmwc/typography';
import { APP_NAME, APP_TAGLINE } from '../Configs/appConfig';
import { Instagram } from '../Assets/Icons/Icons';
import { Icon } from '@rmwc/icon';
import RazorpayBadge from './MicroComponents/RazorpayBadge';
import PaypalBadge from './MicroComponents/PaypalBadge';

function mapDispatchToProps(dispatch){
    return {
        userSignedIn: user=> dispatch(userSignedIn(user)),
        userSignedOut: ()=> dispatch(userSignedOut()),
        userSignInError: (errMess) => dispatch(userSignInError(errMess)),
        setRefreshingToken: (isRefresh) => dispatch(setRefreshingToken(isRefresh))
    }
}
class Footer extends React.Component {
    componentDidMount(){ 
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.props.setRefreshingToken(true);
                user.getIdTokenResult(true)
                .then((idTokenResult) => {
                    const user1 = {
                        displayName: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        photoURL: user.photoURL,
                        superadmin: !!idTokenResult.claims.superadmin,
                        uid: user.uid,
                        subscription: !!idTokenResult.claims.membership || !!idTokenResult.claims.stripeRole || idTokenResult.claims.superadmin
                    };
                    if(!user1.photoURL)
                    user1.photoURL = document.location.origin + '/images/nodp.webp';
                    if(!user1.displayName)
                    user1.displayName = user1.email;
                    this.props.userSignedIn(user1); 
                    setRefreshingToken(false);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            else {
              this.props.userSignedOut();
            }
          });
    }
    render(){
        return (
            <>
            <footer className="footer p-5">
                <Row>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center p-2">
                                <Link to={HOME}>
                                    <img 
                                        src={window.location.origin+'/logo192.webp'}
                                        className='img-fluid rounded-circle footer-logo'
                                        alt={APP_NAME}
                                    />
                                </Link>
                            </Col>
                            <Col xs={12}>
                                <Link to={HOME}>
                                    <Typography use="headline6" className="footer-subtitle">
                                        {APP_NAME}
                                    </Typography>
                                </Link>
                            </Col>
                            <Col xs={12}>
                                <Typography use="subtitle1">
                                    {APP_TAGLINE}
                                </Typography>
                            </Col>
                            <Col xs={12 } className="mb-2 d-flex flex-column">
                                <Typography use="subtitle1" className="footer-subtitle">Follow us on instagram</Typography>
                                <Typography use="body2"> Follow us on instagram for quick support, requesting features and latest updates.</Typography>
                                <a href="https://www.instagram.com/tournify.in/" style={{textDecoration: "none", maxWidth: "250px", width: "100%"}} target="_blank" without="true" rel="noopener noreferrer">
                                    <div className="send-instagram">
                                        <Instagram/>
                                        {' '}
                                        Follow Us On Instagram
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4} className="d-flex flex-column ">
                        <Row>
                        <Col xs={6} md={12}>
                            <Typography use="headline6" className="footer-subtitle">
                                Information
                            </Typography>
                            <div className="d-flex flex-column">
                                <Link to={ABOUT} className="footer-link">About</Link>
                                <Link to={PRICING} className="footer-link">Plans & Pricing</Link>
                                <Link to={PRIVACY} className="footer-link">Privacy</Link>
                                <Link to={TERMS_OF_USE} className="footer-link">Terms of use</Link>
                                <Link to={DISCLAIMER} className="footer-link">Disclaimer</Link>
                                <Link to={REFUND_POLICY} className="footer-link">Refunds &amp; Cancellations Policy</Link>
                            </div>
                        </Col>
                        <Col xs={6} md={12}>
                            <Typography use="headline6" className="footer-subtitle">
                                Helpful Links
                            </Typography>
                            <div className="d-flex flex-column">
                                <Link to={ADMIN} className="footer-link">Dashboard</Link>
                                <Link to={TOURNAMENTS} className="footer-link">Find Tournaments</Link>
                                <Link to={CONTACT} className="footer-link">Contact</Link>
                                <Link to={FAQ} className="footer-link">FAQ</Link>
                            </div>
                        </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Typography use="headline6" className="footer-subtitle">
                            Reach Us
                        </Typography>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="d-flex align-items-center"><Icon icon={{icon: "email"}}/></span>
                                <span className="footer-link d-flex align-items-center"><a href="mailto:contact@esportsweb.in">contact@esportsweb.in</a></span>
                            </div>         
                            <Typography use="body1" className="footer-link">
                                Do you have any query or need any help? Feel free to reach us. 
                            </Typography>
                            <Link to={CONTACT}>
                                <button className="contact-button">Contact Us</button>
                            </Link>                       
                        </div>
                        <Typography use="headline6" className="footer-subtitle mt-2">
                            Payments Powered By
                        </Typography>
                        <Row>
                            <Col xs={12} className="d-flex flex-row">
                                <RazorpayBadge/>
                                <PaypalBadge/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </footer>
            </>
        );
    }
}

export default connect(null,mapDispatchToProps)(Footer);