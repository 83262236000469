import React from 'react';
import './bootstrap.scss';
import '@rmwc/theme/styles';
import '@rmwc/top-app-bar/styles';
import '@rmwc/icon/styles';
import '@rmwc/drawer/styles';
import '@rmwc/list/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/elevation/styles'
import '@rmwc/button/styles';
import '@rmwc/textfield/styles';
import '@rmwc/radio/styles';
import '@rmwc/select/styles';
import '@rmwc/ripple/styles';
import '@rmwc/switch/styles';
import '@rmwc/tabs/styles';
import '@rmwc/icon-button/styles';
import '@rmwc/data-table/styles';
import '@rmwc/dialog/styles';
import '@rmwc/typography/styles';
import '@rmwc/card/styles';
import '@rmwc/badge/styles';
import '@rmwc/checkbox/styles';
import '@rmwc/avatar/styles';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './Components/MicroComponents/ScrollToTop';
import { configureStore } from './redux/configureStore';
import MainComponent from './Components/MainComponent';
import firebase from './Components/firebase/firebase';
import {ThemeProvider} from '@rmwc/theme';
import theme from './theme';
import { DialogQueue } from '@rmwc/dialog';
import { dialogQueue } from './functions/common';
import UserCreditObserver from './Components/Admin/UserCreditObserver';

const store = configureStore();

firebase.initialize();
function App() {
  return (
    <Provider store = {store}>
        <BrowserRouter>
          <ScrollToTop />
          <DialogQueue
            preventOutsideDismiss
            dialogs={dialogQueue.dialogs}/>
          <ThemeProvider options={theme}>
            <MainComponent/>
          </ThemeProvider>
        </BrowserRouter>
        <UserCreditObserver />
      </Provider>
  );
}

export default App;
